import { FunctionComponent, lazy, StrictMode, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { AppWithI18n } from '@common/language/i18n';
import AppErrorBoundary from '@common/errorHandling/AppErrorBoundary';
import AppWithFocus from '@common/focus/AppWithFocus';
import AppWithScrollToggle, {
  useToggleScrollContext,
} from '@common/scroll/AppWithScrollToggle';
import Spinner from '@common/basics/Spinner';
import { trackMessage } from '@common/errorHandling/errorTracking';
import { subsituteIdsWithPlacedholders } from '@common/navigation/urlSubstitutions';

// refresh page when Vite fails to load dynamic imports. See: https://vitejs.dev/guide/build.html#load-error-handling
window.addEventListener('vite:preloadError', () => {
  trackMessage(
    `Vite preload error - reloading page at: ${subsituteIdsWithPlacedholders(
      location.pathname
    )}`
  );

  window.location.reload();
});

const OpenPortalWrapper = lazy(() => import('./js/openPortal'));
const PrivatePersonAppWrapper = lazy(() => import('./js/privatePerson'));
const RegisterAppWrapper = lazy(() => import('./js/registration'));
const RedirectAppWrapper = lazy(() => import('./js/redirect'));
const OrganizationWrapper = lazy(() => import('./js/organization'));
const OffentligWrapper = lazy(() => import('./js/offentlig'));
const PortalWrapper = lazy(() => import('./js/portal'));
const AdminAppWrapper = lazy(() => import('./js/admin'));

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/*" element={<PortalWrapper />} />
      <Route path="_/*" element={<OpenPortalWrapper />} />
      <Route path="virksomhet/*" element={<OrganizationWrapper />} />
      <Route path="offentlig/*" element={<OffentligWrapper />} />
      <Route path="privat/*" element={<PrivatePersonAppWrapper />} />
      <Route path="registrering/*" element={<RegisterAppWrapper />} />
      <Route path="admin/*" element={<AdminAppWrapper />} />
      <Route path="signere/*" element={<RedirectAppWrapper />} />
    </>
  )
);

const Index: FunctionComponent = () => {
  const { disableScroll } = useToggleScrollContext();
  return (
    <div className={`app${disableScroll ? '--no-scroll' : ''}`}>
      <Suspense fallback={<Spinner fullScreen />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};

createRoot(document.getElementById('react') as HTMLElement).render(
  <StrictMode>
    <AppWithI18n>
      <AppErrorBoundary>
        <AppWithFocus>
          <AppWithScrollToggle>
            <Index></Index>
          </AppWithScrollToggle>
        </AppWithFocus>
      </AppErrorBoundary>
    </AppWithI18n>
  </StrictMode>
);
