import Cookies from 'js-cookie';

export function save(key: string, value: string, ttl = 999): void {
  Cookies.set(key, value, {
    expires: ttl,
    path: '/',
    secure: true,
  });
}

export function retrieve(key: string, fallback = ''): string {
  return Cookies.get(key) || fallback;
}
