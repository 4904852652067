import { FunctionComponent } from 'react';

/* SHOULD BE REPLACED BY, OR REFACTORED TO USE ./spinner/SpinnerHW*/
const Spinner: FunctionComponent<{
  condition?: boolean;
  fullScreen?: boolean;
}> = ({ condition = true, fullScreen = false }) => {
  if (condition) {
    return (
      <div
        data-testid="spinner"
        className={
          fullScreen ? 'spinner-box-wrapper-full-screen' : 'spinner-box-wrapper'
        }
        aria-busy="true"
        aria-live={fullScreen ? 'assertive' : 'polite'}
      >
        {' '}
        <div className="spinner-box" data-testid="spinner-box">
          <div className="spinner1" />
          <div className="spinner2" />
          <div className="spinner3" />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Spinner;
