import { FunctionComponent } from 'react';
import { useI18n } from '../language/i18n';

const ContactSupport: FunctionComponent<{
  heading: string;
  description: string;
  email: string;
  phoneNumber: string;
  internationalPhoneNumber?: string;
  openingHours: string;
}> = ({
  heading,
  description,
  email,
  phoneNumber,
  internationalPhoneNumber,
  openingHours,
}) => {
  const [translation] = useI18n();

  return (
    <article className="hw-card">
      <div className="hw-card__body">
        <h3 className="hw-text-title hw-h4 hw-color-primary-darker">
          {heading}
        </h3>
        <p
          className="hw-card__subtitle hw-block--mt-small-2"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="">
          <p className="hw-block--mt-medium-1 hw-text-technical">
            {translation.help.contact_info.email}
            <a className="hw-link hw-link--small" href={`mailto:${email}`}>
              {email}
            </a>
          </p>
          <p className="hw-text-technical hw-block--mt-small-2">
            {phoneNumber}
          </p>
          {internationalPhoneNumber && (
            <p className="hw-text-small hw-block--mt-small-2">
              {internationalPhoneNumber}
            </p>
          )}
          <p className="hw-text-technical hw-block--mt-small-2">
            {openingHours}
          </p>
        </div>
      </div>
    </article>
  );
};

export default ContactSupport;
