import { FunctionComponent } from 'react';
import ContactSupportOptions from '../contact/ContactSupportOptions';
import { useI18n } from '../language/i18n';
import Heading from '../pageBuildingBlocks/Heading';
import wondering from './illustrations/Undrende_mann.svg';

import './unhandled-error.less';

const UnhandledError: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <section className="hw-container hw-container--pt hw-container--pb-medium-4">
      <div className="hw-flex hw-block--mt-medium-3-responsive hw-block--mb-medium-3-responsive">
        <div className="text-center--mobile">
          <Heading
            headerText={translation.common.unhandled_error.header}
            extraClassName="text-center--mobile"
          />
          <p className="hw-block--mt-medium-3-responsive">
            {translation.common.unhandled_error.description}
          </p>
          <p
            dangerouslySetInnerHTML={{
              __html: translation.common.unhandled_error.restart_description,
            }}
          />
          <p className="hw-block--mt-large-3-responsive">
            {translation.common.unhandled_error.contact_us}
          </p>
        </div>
        <img
          className="unhandled-error-illustration"
          src={wondering}
          height="300"
          alt=""
        />
      </div>
      <ContactSupportOptions className="hw-block hw-block--mb-medium-3-responsive" />
    </section>
  );
};

export default UnhandledError;
